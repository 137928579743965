import useThemeConfig from "src/themes/useThemeConfig";
import React from "react";

export default function ShowcaseRecommendedProductsList(props) {
  const config = useThemeConfig();
  const ThemedShowcaseRecommendedProductsList =
    config.components.ShowcaseRecommendedProductsList;

  return <ThemedShowcaseRecommendedProductsList {...props} />;
}
